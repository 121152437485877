import React, { Component } from 'react';
import { connect } from 'react-redux';

import { toggleMealPlannerPromoModal } from 'actions/App';
import { logDDEEvent } from 'actions/DDE';
import { setPlanAndShopModalShown } from 'actions/MealPlanning';
import { logEvent } from 'actions/mixpanel';
import { storeAttributionSource } from 'actions/Pro';

import { MEAL_PLANNER_PROMO_KEY } from 'constants/BigModalKeys';
import { DEFAULT } from 'constants/Experiments';
import { icons } from 'constants/FontFaceCharacters';
import { CHECKOUT_SOURCES } from 'constants/Pro';

import { LEARN_MORE_ROUTE } from 'constants/routes';
import { TRACKING, DDE } from 'constants/Tracking';

import Image from 'components/Image';
import Link from 'components/Link';

import { currentUserSelector, planAndShopModalSelector } from 'selectors/auth';
import { hasMealPlannerAccess } from 'selectors/paywall';

import cdn from 'util/cdn';
import { bindInstance } from 'util/dev';
import storage from 'util/storage';

import { getCopy } from './MealPlannerPromoCopy';


const benefits = [
  getCopy(`benefits.one.${DEFAULT}`),
  getCopy(`benefits.two.${DEFAULT}`),
  getCopy(`benefits.three.${DEFAULT}`),
];

const DDE_ACTION_TYPE = 'mealplan_welcome';
const ddeDefaultProps = {
  action_type: DDE_ACTION_TYPE,
  floating: false,
  pop_up: true,
  button_text: getCopy(`learnMore.${DEFAULT}`),
};

export class MealPlannerPromo extends Component {
  constructor() {
    super(...arguments);
    bindInstance(this);
  }

  componentDidMount() {
    const { logDDEEvent, track } = this.props;

    track(TRACKING.PROMPT_VIEW, {
      'Prompt Type': TRACKING.MEAL_PLAN_PROMO,
    });

    logDDEEvent(DDE.CTA_IMPRESSION, ddeDefaultProps);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isLoggedIn && this.props.isLoggedIn) {
      this.props.setPlanAndShopModalShown();
    }

    if (this.props.hasMealPlannerAccess && this.props.hasSeenPlanAndShopModal) {
      this.props.toggleMealPlannerPromoModal(false);
    }
  }

  onDismissClick() {
    const { isLoggedIn, toggleMealPlannerPromoModal, track, setPlanAndShopModalShown } = this.props;

    track(TRACKING.PROMPT_CLICK, {
      'Prompt Action': TRACKING.CLOSE_DIALOG_BOX,
      'Prompt Type': TRACKING.MEAL_PLAN_PROMO,
    });

    if (isLoggedIn) {
      setPlanAndShopModalShown();
    } else {
      storage.perm.set(MEAL_PLANNER_PROMO_KEY, true);
    }

    toggleMealPlannerPromoModal(false, {
      isClosed: true,
    });
  }

  onCtaClick() {
    const { isLoggedIn, logDDEEvent, toggleMealPlannerPromoModal, setPlanAndShopModalShown } = this.props;

    this.props.track(TRACKING.PROMPT_CLICK, {
      'Prompt Action': getCopy(`learnMore.${DEFAULT}`),
      'Prompt Type': TRACKING.MEAL_PLAN_PROMO,
    });
    logDDEEvent(DDE.CTA_CLICK, ddeDefaultProps);
    toggleMealPlannerPromoModal(false);

    if (isLoggedIn) {
      setPlanAndShopModalShown();
    } else {
      storage.perm.set(MEAL_PLANNER_PROMO_KEY, true);
    }

    this.props.storeAttributionSource(CHECKOUT_SOURCES.mealPlan);
  }

  render() {
    return (
      <div className="meal-planner-promo">
        <Image className="promo-main-image" src={`${cdn.imagePrefix()}/MealPlanner-promo.jpg`} />
        <span
          className="y-icon cancel greyscale-3 p3-text"
          data-icon={icons.xSmall}
          onClick={this.onDismissClick}
        />
        <div className="meal-planner-content">
          <h4 className="font-bold gr-title h2-text primary-dark">{getCopy(`title.${DEFAULT}`)}</h4>
          <h5 className="font-regular gr-desc p2-text greyscale-2">{getCopy(`description.${DEFAULT}`)}</h5>
          {
            benefits.map((benefit, i) => {
              return (
                <div key={i} className="benefit-item p2-text">
                  <span className="y-icon checkmark primary-teal" data-icon={icons.checkSmall} />
                  <h3 className="benefit-description p2-text greyscale-1 font-bold">{benefit}</h3>
                </div>);
            })
          }
          <div className="starter-wrapper">
            <Link
              className="btn-primary get-started"
              href={LEARN_MORE_ROUTE}
              onClick={this.onCtaClick}
            >
              {getCopy(`learnMore.${DEFAULT}`)}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

MealPlannerPromo.propTypes = {
  hasMealPlannerAccess: YummlyPropTypes.bool,
  hasSeenPlanAndShopModal: YummlyPropTypes.bool,
  isLoggedIn: YummlyPropTypes.bool,
  logDDEEvent: YummlyPropTypes.action,
  setPlanAndShopModalShown: YummlyPropTypes.action,
  storeAttributionSource: YummlyPropTypes.action,
  toggleMealPlannerPromoModal: YummlyPropTypes.action,
  track: YummlyPropTypes.action,
};

const mapStateToProps = state => {
  return {
    hasMealPlannerAccess: hasMealPlannerAccess(state),
    hasSeenPlanAndShopModal: planAndShopModalSelector(state) || storage.perm.get(MEAL_PLANNER_PROMO_KEY),
    isLoggedIn: Boolean(currentUserSelector(state)),
  };
};

const mapDispatchToProps = {
  logDDEEvent,
  setPlanAndShopModalShown,
  storeAttributionSource,
  toggleMealPlannerPromoModal,
  track: logEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(MealPlannerPromo);