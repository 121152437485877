import { withTest } from 'modules/reactAB';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { toggleLoginModal } from 'actions/App';
import { logDDEEvent } from 'actions/DDE';
import { logEvent } from 'actions/mixpanel';
import { showPaywallModal } from 'actions/Paywall';

import Icon from 'components/Pasta/Icon';
import Image from 'components/Image';
import Link from 'components/Link';

import { PAYWALL_EXPERIMENT_IDS, VARIANT_1, VARIANT_2, VARIANT_3 } from "constants/Experiments";
import { CHECKOUT_SOURCES, YUMMLY_PRO_TRIAL_LENGTH } from 'constants/Pro';

import { LEARN_MORE_ROUTE, MEAL_PLANNING_ROUTE, SUBSCRIPTION_PLANS_ROUTE } from 'constants/routes';

import { isLoggedInSelector } from 'selectors/auth';
import { featureFlagSelector } from 'selectors/metadata';
import { hasTrialedPro } from 'selectors/pro';
import { availableAnnualPlanSelector } from 'selectors/subscription';

import cdn from 'util/cdn';
import classNames from 'util/classNames';
import { deepProperty } from 'util/object';
import { isAndroid, isIOS } from 'util/userAgent';
import { storePostCheckoutUrl } from 'actions/Pro';
import RegistrationModal from 'bundles/modals/components/RegistrationModal/RegistrationModal';

export const PAYWALL_MODAL_VARIANT_IDS = {
  DEFAULT: 'default',
  DEFAULT_V2: 'default_v2',
  RECIPE_CONTEXTUAL: 'recipe_contextual',
  RECIPE_CONTEXTUAL_COPY_1: 'recipe_contextual_copy1',
  RECIPE_CONTEXTUAL_COPY_2: 'recipe_contextual_copy2',
  RECIPE_CONTEXTUAL_COPY_3: 'recipe_contextual_copy3',
  NUTRITION_INFO: 'nutrition_info',
  FILTER_CONTEXTUAL: 'filter_contextual',
  MEAL_PLANNER_CONTEXTUAL: 'mealplanner_contextual',
  POST_SIGNUP: 'post_signup',
};

const PAYWALL_MODAL_VARIANT_CONFIGURATIONS = {
  DEFAULT: {
    id: PAYWALL_MODAL_VARIANT_IDS.DEFAULT,
    subscriptionUpsellVersion: 'general',
    title: 'Bring More to the Table',
    secondaryButtonText: "Learn More",
    secondaryButtonTarget: LEARN_MORE_ROUTE,
    mobileIncentiveDescription: "Healthier options. Easier weeknights. Smarter tools. Tastier meals. Subscribe now for:",
    incentives: {
      desktop: [
        {
          title: "Healthier options",
          subtitle: 'Personalized recommendations tailored to help meet your goals',
        },
        {
          title: 'Easier weekdays',
          subtitle: 'Integrated meal planning, recipe scheduling & grocery shopping',
        },
        {
          title: "Smarter tools",
          subtitle: "Scan ingredients and get recipe recommendations to use them up",
        },
        {
          title: "Tastier meals",
          subtitle: "Step-by-step cooking on select recipes to level-up your skills",
        },
      ],
      mobile: [
        {
          title: "Recommended Recipes",
          subtitle: " to help meet your goals",
        },
        {
          title: "Integrated tools",
          subtitle: " to plan, schedule & shop",
        },
        {
          title: "Scan-and-search",
          subtitle: " ingredients to use up food you have",
        },
        {
          title: "Cooking instruction",
          subtitle: " to level-up your skills",
        },
      ],
    },
  },
  [PAYWALL_MODAL_VARIANT_IDS.DEFAULT_V2]: {
    id: PAYWALL_MODAL_VARIANT_IDS.DEFAULT_V2,
    subscriptionUpsellVersion: 'general_v2',
    title: 'Bring More \nto the Table',
    secondaryButtonText: "Learn More",
    secondaryButtonTarget: LEARN_MORE_ROUTE,
    mobileIncentiveDescription: "Healthier options. Easier weeknights. Smarter tools. Tastier meals. Subscribe now for:",
    ctaButtonText: 'Try Free For 30 Days',
    ctaButtonTextHasTrialed: 'Get Started',
    disclaimerText: 'Auto-renews at selected price after trial unless you cancel.',
    incentives:
      [
        {
          subtitle: 'Healthy personalized recipe recommendations',
        },
        {
          subtitle: 'Time saving meal planning & grocery shopping tools',
        },
        {
          subtitle: "Smart search tools & dietary filters",
        },
        {
          subtitle: "Clear step-by-step instructions for tastier meals",
        },
      ],
  },
  [PAYWALL_MODAL_VARIANT_IDS.POST_SIGNUP]: {
    id: PAYWALL_MODAL_VARIANT_IDS.POST_SIGNUP,
    subscriptionUpsellVersion: 'onboarding',
    title: 'Thanks for \nJoining Yummly!',
    subtitle: 'Subscribe now for:',
    secondaryButtonText: "No Thanks",
    ctaButtonText: 'Try Free For 30 Days',
    ctaButtonTextHasTrialed: 'Get Started',
    incentives:
      [
        {
          subtitle: 'Personalized content',
        },
        {
          subtitle: 'Smart search tools',
        },
        {
          subtitle: "Meal planning & recipe scheduling",
        },
        {
          subtitle: "Exclusive instructional videos",
        },
      ],
  },
  [PAYWALL_MODAL_VARIANT_IDS.NUTRITION_INFO]: {
    id: PAYWALL_MODAL_VARIANT_IDS.NUTRITION_INFO,
    subscriptionUpsellVersion: 'nutrition_info',
    title: 'Unlock nutrition info with a free trial',
    secondaryButtonText: "Learn More",
    secondaryButtonTarget: LEARN_MORE_ROUTE,
    ctaButtonText: 'Try Free For 30 Days',
    ctaButtonTextHasTrialed: 'Get Started',
    disclaimerText: 'Auto-renews at selected price after trial unless you cancel.',
    incentives:
      [
        {
          subtitle: 'Smart search tools & dietary filters',
        },
        {
          subtitle: 'Healthy recipes in 30 minutes or less',
        },
        {
          subtitle: 'Meal planning & recipe scheduling',
        },
        {
          subtitle: 'Recommendations to use up what you have',
        },
      ],
  },
  [PAYWALL_MODAL_VARIANT_IDS.FILTER_CONTEXTUAL]: {
    id: PAYWALL_MODAL_VARIANT_IDS.FILTER_CONTEXTUAL,
    subscriptionUpsellVersion: 'filter',
    title: 'Find the recipes\nyou want, fast.',
    secondaryButtonText: "Learn More",
    secondaryButtonTarget: LEARN_MORE_ROUTE,
    ctaButtonText: 'Try Free For 30 Days',
    ctaButtonTextHasTrialed: 'Get Started',
    disclaimerText: 'Auto-renews at selected price after trial unless you cancel.',
    incentives:
      [
        {
          subtitle: 'Smart search tools & filters',
        },
        {
          subtitle: 'Personalized recipe recommendations',
        },
        {
          subtitle: 'Exclusive step-by-step video recipes',
        },
        {
          subtitle: 'Meal planning & recipe scheduling',
        },
      ],
  },
  [PAYWALL_MODAL_VARIANT_IDS.RECIPE_CONTEXTUAL]: {
    id: PAYWALL_MODAL_VARIANT_IDS.RECIPE_CONTEXTUAL,
    subscriptionUpsellVersion: 'recipe',
    title: 'Unlock this recipe with a free trial',
    secondaryButtonText: "Learn More",
    secondaryButtonTarget: LEARN_MORE_ROUTE,
    ctaButtonText: 'Try Free For 30 Days',
    ctaButtonTextHasTrialed: 'Get Started',
    disclaimerText: 'Auto-renews at selected price after trial unless you cancel.',
    incentives:
      [
        {
          subtitle: 'Exclusive step-by-step video recipes',
        },
        {
          subtitle: 'Recommendations to use up what you have',
        },
        {
          subtitle: "Meal planning & recipe scheduling",
        },
        {
          subtitle: "Smart tools to discover rut-busting recipes",
        },
      ],
  },
  [PAYWALL_MODAL_VARIANT_IDS.RECIPE_CONTEXTUAL_COPY_1]: {
    id: PAYWALL_MODAL_VARIANT_IDS.RECIPE_CONTEXTUAL_COPY_1,
    subscriptionUpsellVersion: 'recipe_copy_1',
    title: 'Unlock 2,000+ premium recipes and more',
    secondaryButtonText: 'Learn More',
    secondaryButtonTarget: LEARN_MORE_ROUTE,
    ctaButtonText: 'Try Free For 30 Days',
    ctaButtonTextHasTrialed: 'Get Started',
    disclaimerText: 'Auto-renews at selected price after trial unless you cancel.',
    incentives:
      [
        {
          subtitle: 'Clear step-by-step instructions for tastier meals',
        },
        {
          subtitle: 'Healthy personalized recipe recommendations',
        },
        {
          subtitle: 'Time saving meal planning & grocery shopping tools',
        },
        {
          subtitle: 'Smart search tools & dietary filters',
        },
      ],
  },
  [PAYWALL_MODAL_VARIANT_IDS.RECIPE_CONTEXTUAL_COPY_2]: {
    id: PAYWALL_MODAL_VARIANT_IDS.RECIPE_CONTEXTUAL_COPY_2,
    subscriptionUpsellVersion: 'recipe_copy_2',
    title: 'Unlock unlimited recipe access',
    secondaryButtonText: 'Learn More',
    secondaryButtonTarget: LEARN_MORE_ROUTE,
    ctaButtonText: 'Try Free For 30 Days',
    ctaButtonTextHasTrialed: 'Get Started',
    disclaimerText: 'Auto-renews at selected price after trial unless you cancel.',
    incentives:
      [
        {
          subtitle: 'Clear step-by-step instructions for tastier meals',
        },
        {
          subtitle: 'Healthy personalized recipe recommendations',
        },
        {
          subtitle: 'Time saving meal planning & grocery shopping tools',
        },
        {
          subtitle: 'Smart search tools & dietary filters',
        },
      ],
  },
  [PAYWALL_MODAL_VARIANT_IDS.RECIPE_CONTEXTUAL_COPY_3]: {
    id: PAYWALL_MODAL_VARIANT_IDS.RECIPE_CONTEXTUAL_COPY_3,
    subscriptionUpsellVersion: 'recipe_copy_3',
    title: 'Check out our premium recipes and tools',
    secondaryButtonText: 'Learn More',
    secondaryButtonTarget: LEARN_MORE_ROUTE,
    ctaButtonText: 'Try Free For 30 Days',
    ctaButtonTextHasTrialed: 'Get Started',
    disclaimerText: 'Auto-renews at selected price after trial unless you cancel.',
    incentives:
      [
        {
          subtitle: 'Clear step-by-step instructions for tastier meals',
        },
        {
          subtitle: 'Healthy personalized recipe recommendations',
        },
        {
          subtitle: 'Time saving meal planning & grocery shopping tools',
        },
        {
          subtitle: 'Smart search tools & dietary filters',
        },
      ],
  },
  [PAYWALL_MODAL_VARIANT_IDS.MEAL_PLANNER_CONTEXTUAL]: {
    id: PAYWALL_MODAL_VARIANT_IDS.MEAL_PLANNER_CONTEXTUAL,
    subscriptionUpsellVersion: 'meal_planner',
    title: 'Simplify mealtime with a free trial',
    secondaryButtonText: "Learn More",
    secondaryButtonTarget: `${MEAL_PLANNING_ROUTE}?marketing=true`,
    ctaButtonText: 'Try Free For 30 Days',
    ctaButtonTextHasTrialed: 'Get Started',
    disclaimerText: 'Auto-renews at selected price after trial unless you cancel.',
    incentives:
      [
        {
          subtitle: 'Personalized Meal Plan recommendations',
        },
        {
          subtitle: 'One-click custom Shopping List creation',
        },
        {
          subtitle: "Recipe scheduling and calendar integration",
        },
        {
          subtitle: "Healthy recipes in 30 minutes or less",
        },
      ],
  },
};

const ContextualPaywallModal = ({
  config,
  ctaClick,
  closeModal,
  toggleLoginModal,
  hasTrialedPro,
  logEvent,
  mixpanelBaseProps,
  isLoggedIn,
}) => {

  const payWallModalClassNames = classNames({
    'paywall-modal': true,
    'contextual': true,
  });

  const imgLogoWhite = `${cdn.imagePrefix()}/yummly.svg`;
  const android = isAndroid();
  const iOS = isIOS();

  function onClickedLogin() {
    toggleLoginModal(true);
    logEvent('Prompt Click', {
      ...mixpanelBaseProps,
      'Prompt Action': `Sign In`,
    });
  }

  function onClickedSecondaryButton() {
    logEvent('Prompt Click', {
      ...mixpanelBaseProps,
      'Prompt Action': `${config.secondaryButtonText} Click`,
    });
    closeModal();
  }

  function onClickedCloseModal() {
    logEvent('Prompt Click', {
      ...mixpanelBaseProps,
      'Prompt Action': `Exit`,
    });
    closeModal(true);
  }

  return (<div className={payWallModalClassNames}>
    <div className="paywall-v2-content">
      <div className="top-image" />
      <div className="paywall-content">
        <div className="accolade-section-upper">
          <Image className="logo" src={imgLogoWhite} />
          <div className="vertical-separator" />
          <Image className="editors-choice" src={`${cdn.imagePrefix()}/contextual-upsell/editorsChoiceDesktop/editorsChoiceDesktop@2x.png`} />
        </div>
        <div className="images">

          {config.id === PAYWALL_MODAL_VARIANT_IDS.DEFAULT_V2 &&
            <>
              <Image className="steak-desktop" src={`${cdn.imagePrefix()}/contextual-upsell/steakDesktop/steakDesktop@2x.png`} />
              <Image className="steak-mobile" src={`${cdn.imagePrefix()}/contextual-upsell/steakMobile/steakMobile@2x.png`} />
            </>
          }
          {
            config.recipeInfo &&
              <div className="recipe-contextual-image-wrapper" >
                <div className="recipe-contextual-image" style={{
                  backgroundImage: `url(${config.recipeInfo.imageURL})`,
                }}>
                  <div className="recipe-contextual-title">{config.recipeInfo.displayName} </div>
                </div>
              </div>
          }
          {
            config.id === PAYWALL_MODAL_VARIANT_IDS.NUTRITION_INFO &&
            <div className="recipe-contextual-image-wrapper" >
              <div className="recipe-contextual-image-nutrition" />
            </div>
          }
          {
            config.id === PAYWALL_MODAL_VARIANT_IDS.FILTER_CONTEXTUAL &&
            <div className="recipe-contextual-image-wrapper" >
              <div className="recipe-contextual-image-filter" />
            </div>
          }
          {
            config.id === PAYWALL_MODAL_VARIANT_IDS.MEAL_PLANNER_CONTEXTUAL &&
            <div className="recipe-contextual-image-wrapper" >
              <div className="recipe-contextual-image-mealplanner" />
            </div>
          }
        </div>
        <h2 className="title font-bold">{config.title}</h2>
        <div className="paywall-incentives">
          <p className="incentive-description p2-text">{config.incentiveDescription}</p>
          <ul className="checkmarks">
            {config.incentives.map(({ title, subtitle }) => {
              return (
                <li className="check" key={subtitle}>
                  <Icon iconName="checkSmall" className="checkmark" />
                  <p className="descriptor">
                    <span className="font-bold p2-text">{title}</span>
                    <span className="font-normal p2-text">{subtitle}</span>
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="accolade-section-lower">
          {!android && !iOS && <Image className="editors-choice" src={`${cdn.imagePrefix()}/contextual-upsell/editorsChoiceDesktop/editorsChoiceDesktop@2x.png`} />}
          {android && <Image className="editors-choice-android" src={`${cdn.imagePrefix()}/contextual-upsell/googlePlayStore/googlePlayStore.png`} />}
          {iOS && <Image className="editors-choice-ios" src={`${cdn.imagePrefix()}/contextual-upsell/editorsChoiceApple/editorsChoiceApple.png`} />}
        </div>
        <div className="cta-section">
          <Link
            id="pro-recipe-modal-trial-link"
            className="btn-primary checkout-link"
            href={SUBSCRIPTION_PLANS_ROUTE}
            onClick={ctaClick}
          >
            {hasTrialedPro ? config.ctaButtonTextHasTrialed : config.ctaButtonText}
          </Link>
          <Link className="btn-secondary subscription-link" href={config.secondaryButtonTarget} onClick={onClickedSecondaryButton}>
            {config.secondaryButtonText}
          </Link>
          <div className="legal-disclaimer">{config.disclaimerText}</div>
          <Link className="learn-more font-bold" href={config.secondaryButtonTarget} onClick={onClickedSecondaryButton}>{config.secondaryButtonText}</Link>

          {!isLoggedIn && <><div className="horizontal-rule" />
            <div className="sign-in">{'Already a subscriber?'}
              <Link
                onClick={onClickedLogin}
                className="sign-in-btn font-bold"
              > {'Sign In'}
              </Link>
            </div></>}
        </div>
      </div>
    </div>
    <Icon className="close-modal" iconName="xLarge" onClick={onClickedCloseModal} />
  </div>);
};


ContextualPaywallModal.propTypes = {
  closeModal: YummlyPropTypes.func,
  config: YummlyPropTypes.objectOrString,
  ctaClick: YummlyPropTypes.func,
  hasTrialedPro: YummlyPropTypes.bool,
  isLoggedIn: YummlyPropTypes.bool,
  logEvent: YummlyPropTypes.action,
  mixpanelBaseProps: YummlyPropTypes.objectOrString,
  toggleLoginModal: YummlyPropTypes.action,
};

function PaywallModal(props) {
  const {
    contextualUpsellRecipeCopyVariant,
    contextualUpsellNutritionVariant,
    contextualUpsellFilterVariant,
    contextualUpsellMealPlanVariant,
  } = props;

  const modalVariantID = deepProperty(props.modalProps, 'variant');
  const contentId = deepProperty(props.modalProps, 'contentId');
  const recipeInfo = deepProperty(props.modalProps, 'recipeInfo');

  const isRecipeContextualUpsell = modalVariantID === PAYWALL_MODAL_VARIANT_IDS.RECIPE_CONTEXTUAL && (contextualUpsellRecipeCopyVariant && contextualUpsellRecipeCopyVariant !== "default");

  const isNutritionContextualUpsell = modalVariantID === PAYWALL_MODAL_VARIANT_IDS.NUTRITION_INFO && (contextualUpsellNutritionVariant && contextualUpsellNutritionVariant !== "default");

  const isFilterContextualUpsell = modalVariantID === PAYWALL_MODAL_VARIANT_IDS.FILTER_CONTEXTUAL && (contextualUpsellFilterVariant && contextualUpsellFilterVariant !== "default");

  const isMealPlannerUpsell = modalVariantID === PAYWALL_MODAL_VARIANT_IDS.MEAL_PLANNER_CONTEXTUAL && (contextualUpsellMealPlanVariant && contextualUpsellMealPlanVariant !== "default");

  const isPostSignupUpsell = modalVariantID === PAYWALL_MODAL_VARIANT_IDS.POST_SIGNUP;

  let paywallConfig = PAYWALL_MODAL_VARIANT_CONFIGURATIONS[PAYWALL_MODAL_VARIANT_IDS.DEFAULT_V2];

  if (isRecipeContextualUpsell) {
    paywallConfig = PAYWALL_MODAL_VARIANT_CONFIGURATIONS[PAYWALL_MODAL_VARIANT_IDS.RECIPE_CONTEXTUAL];

    if (contextualUpsellRecipeCopyVariant === VARIANT_1) {
      paywallConfig = PAYWALL_MODAL_VARIANT_CONFIGURATIONS[PAYWALL_MODAL_VARIANT_IDS.RECIPE_CONTEXTUAL_COPY_1];
    }

    if (contextualUpsellRecipeCopyVariant === VARIANT_2) {
      paywallConfig = PAYWALL_MODAL_VARIANT_CONFIGURATIONS[PAYWALL_MODAL_VARIANT_IDS.RECIPE_CONTEXTUAL_COPY_2];
    }

    if (contextualUpsellRecipeCopyVariant === VARIANT_3) {
      paywallConfig = PAYWALL_MODAL_VARIANT_CONFIGURATIONS[PAYWALL_MODAL_VARIANT_IDS.RECIPE_CONTEXTUAL_COPY_3];
    }
    paywallConfig.recipeInfo = recipeInfo;
  }

  if (isNutritionContextualUpsell) {
    paywallConfig = PAYWALL_MODAL_VARIANT_CONFIGURATIONS[PAYWALL_MODAL_VARIANT_IDS.NUTRITION_INFO];
  }

  if (isFilterContextualUpsell) {
    paywallConfig = PAYWALL_MODAL_VARIANT_CONFIGURATIONS[PAYWALL_MODAL_VARIANT_IDS.FILTER_CONTEXTUAL];
  }

  if (isMealPlannerUpsell) {
    paywallConfig = PAYWALL_MODAL_VARIANT_CONFIGURATIONS[PAYWALL_MODAL_VARIANT_IDS.MEAL_PLANNER_CONTEXTUAL];
  }

  if (isPostSignupUpsell) {
    paywallConfig = PAYWALL_MODAL_VARIANT_CONFIGURATIONS[PAYWALL_MODAL_VARIANT_IDS.POST_SIGNUP];
  }

  const source = props.source;
  const ctaButtonText = props.hasTrialedPro ? 'Subscribe Now' : `Try Free For ${YUMMLY_PRO_TRIAL_LENGTH} Days`;

  const promptTypeActionType = 'subscription_upsell';
  const subscriptionUpsellVersion = paywallConfig.subscriptionUpsellVersion;

  const mixpanelProps = {
    'Prompt Type': promptTypeActionType,
    'Subscription Upsell Version': subscriptionUpsellVersion,
    'Lead Generating Type': source,
  };

  const ddeProps = {
    action_type: promptTypeActionType,
    button_text: ctaButtonText,
    floating: false,
    pop_up: true,
    subscription_upsell_version: subscriptionUpsellVersion,
    lead_generating_type: source,
  };

  if (contentId) {
    mixpanelProps['Content ID'] = contentId;
    ddeProps.content_id = contentId;
  }

  useEffect(() => {
    props.logEvent('Prompt View', mixpanelProps);
    props.logDDEEvent('cta_impression', ddeProps);
  }, []);

  return <RegistrationModal/>;
}

PaywallModal.propTypes = {
  contextualUpsellFilterVariant: YummlyPropTypes.string,
  contextualUpsellMealPlanVariant: YummlyPropTypes.string,
  contextualUpsellNutritionVariant: YummlyPropTypes.string,
  contextualUpsellRecipeCopyVariant: YummlyPropTypes.string,
  hasTrialedPro: YummlyPropTypes.bool,
  logDDEEvent: YummlyPropTypes.action,
  logEvent: YummlyPropTypes.action,
  modalProps: YummlyPropTypes.modalProps,
  source: YummlyPropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    availableAnnualPlan: availableAnnualPlanSelector(state),
    hasTrialedPro: hasTrialedPro(state),
    isLoggedIn: isLoggedInSelector(state),
    mealPlannerUpsellV1Enabled: featureFlagSelector(state)('paywall-modal-variant-meal-planner-v1'),
    modalProps: deepProperty(state, 'app.showModal.modalProps', {}),
    source: deepProperty(state, 'pro.attributionSource', CHECKOUT_SOURCES.default),
    windowDetails: state.windowDetails,
  };
};

const mapDispatchToProps = {
  logEvent,
  logDDEEvent,
  showPaywallModal,
  toggleLoginModal,
  storePostCheckoutUrl,
};

const ConnectedPaywallModal = connect(mapStateToProps, mapDispatchToProps)(withRouter(PaywallModal));
const PaywallModalWithExperiment = withTest(ConnectedPaywallModal, {
  contextualUpsellRecipeVariant: PAYWALL_EXPERIMENT_IDS.RECIPE_CONTEXTUAL,
  contextualUpsellRecipeCopyVariant: PAYWALL_EXPERIMENT_IDS.RECIPE_CONTEXTUAL_COPY,
  contextualUpsellNutritionVariant: PAYWALL_EXPERIMENT_IDS.NUTRITION_INFO,
  contextualUpsellFilterVariant: PAYWALL_EXPERIMENT_IDS.FILTER_CONTEXTUAL,
  contextualUpsellMealPlanVariant: PAYWALL_EXPERIMENT_IDS.MEAL_PLANNER_CONTEXTUAL,
});

export default PaywallModalWithExperiment;


