import React from 'react';

import classNames from 'util/classNames';
import Button from 'components/Pasta/Button';
import Image from 'components/Image';
import cdn from 'util/cdn';
import { toggleLoginModal } from 'actions/App';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { pstIcons } from 'constants/FontFaceCharacters';
import { showPaywallModal } from 'actions/Paywall';
import { LEARN_MORE_ROUTE } from 'constants/routes';
import { currentUserSelector } from 'selectors/auth';


const RegistrationModal = ({
  history,
  showPaywallModal,
  toggleLoginModal,
  isLoggedIn,
}) => {

  const registrationModalClassNames = classNames({
    'registration-modal-content': true,
  });

  function onClickedCreateAccount() {
    toggleLoginModal(true);
  }

  function onClickedLearnMore() {
    showPaywallModal(false);
    history.push(LEARN_MORE_ROUTE);
  }

  function onClickedCloseModal() {
    showPaywallModal(false);
  }

  function onClickedGetStarted() {
    showPaywallModal(false);
  }

  function onClickedSignIn() {
    toggleLoginModal(true);
  }

  let titleText, subtitleText, primaryBtnText;

  if (!isLoggedIn) {
    titleText = 'Get free access to our premium features';
    subtitleText = 'Create your free account to:';
    primaryBtnText = 'Create Free Account';
  } else {
    titleText = 'Thanks for joining Yummly!';
    primaryBtnText = "Get Started";
  }

  const features = ['Get personalized recipe recommendations', 'Plan and schedule meals', 'Add and save your own personal recipes', 'Learn with exclusive videos'];
  const existingUserText = "Already have an account?";
  const signInText = 'Sign In';
  const learnMoreBtnText = 'Learn More';

  return (<div className={registrationModalClassNames}>
    <div className="info-block">
      <div className="info-inner">
        <div className="info-title"> {titleText}</div>
        <div className="info-subtitle pst-2-text-small">{subtitleText}</div>
        <div className="info-ctas">
          {features.map((feature) => {
            return (
              <li className="check" key={feature}>
                <Image className="checkmark" src={`${cdn.imagePrefix()}/img-check.png`} />
                <span className="pst-2-text-small">{feature}</span>
              </li>
            );
          })}
        </div>
        <div className="actions-desktop">
          <div className="btns">
            {!isLoggedIn ? <>
            <Button label={primaryBtnText} onClick={onClickedCreateAccount} contained={true} pastaTwo={true}/>
            <Button label={learnMoreBtnText} onClick={onClickedLearnMore} contained={true} pastaTwo={true} secondary={true}/>
            </>
              : <>
             <Button label={primaryBtnText} onClick={onClickedGetStarted} contained={true} pastaTwo={true}/>
            </>}
          </div>
          {!isLoggedIn && <div className="existing-user flex">
            <span className="existing-user-cta pst-2-text-smaller">{existingUserText}</span>
            <div className="sign-in pst-2-text-smaller bold" onClick={onClickedSignIn}>{signInText}</div>
          </div>}
        </div>
      </div>
    </div>
    <div className="img-block" >
      <Image className="registration-splash" src={`${cdn.imagePrefix()}/subscription/registration-splash.png`} alwaysVisible={true} />
      <div className="actions-mobile">
        <div className="btns">
          <Button label={primaryBtnText} onClick={isLoggedIn ? onClickedGetStarted : onClickedCreateAccount} contained={true} pastaTwo={true}/>
        </div>
        {!isLoggedIn &&
        <div className="learn-more flex">
          <div className="sign-in pst-2-text-smaller bold" onClick={onClickedLearnMore}>{learnMoreBtnText}</div>
        </div>}
        {!isLoggedIn &&
        <div className="existing-user flex">
          <span className="existing-user-cta pst-2-text-smaller">{existingUserText}</span>
          <div className="sign-in pst-2-text-smaller bold" onClick={onClickedSignIn}>{signInText}</div>
        </div>}
      </div>
    </div>
    <div className="close-modal">
      <Button iconCode={pstIcons.F0003_00C__Icon_close} className="close-modal" contained={true} onClick={onClickedCloseModal}/>
    </div>
  </div>);
};


RegistrationModal.propTypes = {
  toggleLoginModal: YummlyPropTypes.action,
};

RegistrationModal.propTypes = {};


const mapStateToProps = (state) => {
  return {
    windowDetails: state.windowDetails,
    isLoggedIn: Boolean(currentUserSelector(state)),
  };
};

const mapDispatchToProps = {
  toggleLoginModal,
  showPaywallModal,
};

const ConnectedRegistrationModal = connect(mapStateToProps, mapDispatchToProps)(withRouter(RegistrationModal));

export default ConnectedRegistrationModal;
